import createConstant from '../utility/createConstant';

export {
    INFORMETS,
    INFORMET_BY_ID,
    INFORMET_PDF_BY_ID,
    SEND_INFORMET_BY_ID,
    INSERT_INFORMET,
    UPDATE_INFORMET
} from './informets';

export const SIGNIN = createConstant('SIGNIN');
