export const decimalNormalize = (negative, integers, decimals ) => value => {
    if(!value) return value;
    const isNegative = value[0] === '-' && negative;
    let sections = value.replace(/[^0-9.]/g, '').split('.');
    if(sections.length === 1) {
        if(isNegative && (sections[0] === '0' || value[1] === '.')) return '-';
        else if(sections[0][0] === '0') return '0';
        else return isNegative ? `-${sections[0].slice(0, integers)}` : sections[0].slice(0, integers);
    }
    else return isNegative ? `-${sections[0] === "" ? '0': sections[0]}.${sections[1].slice(0, decimals)}` : `${sections[0] === "" ? '0': sections[0]}.${sections[1].slice(0, decimals)}`;
}