import React from 'react';

import { Wrapper, Label, ImageWrapper, Image, Field, Error } from './styled';
import { FaFileImage } from 'react-icons/fa';

const adaptFileEventToValue = delegate => e => {
	let image = e.target.files[0] || null;
	let reader = new FileReader();
	reader.onload = e => delegate(e.target.result);
	if(image) reader.readAsDataURL(image);
}

export const ImageInput = ({
	label,
	src,
	id,
	disabled,
	input: {
		value: omitValue,
		onChange,
		onBlur,
		...inputProps
	},
	meta: { touched, error },
	...props
}) => 
    <Wrapper>
		<Label>{ label }</Label>
		<ImageWrapper 
			isError={ error && touched } 
			onClick={ () => document.getElementById(id).click() }
		>
			{ src ? <Image src={ src } /> : <FaFileImage size={ 40 } color='#9E9E9E'/> }
		</ImageWrapper>
        <Field
			id={ id }
            onChange={adaptFileEventToValue(onChange)}
            type="file"
            {...inputProps}
            {...props}
        />
        { touched && error && <Error>{ error }</Error> }
    </Wrapper> 
