import { createStore, compose, applyMiddleware } from 'redux';
import { createBrowserHistory } from 'history';
import { routerMiddleware } from 'connected-react-router';
import createSagaMiddleware from 'redux-saga';

import rootReducer from '../reducers';
import initSagas from '../initSagas';

export const history = createBrowserHistory();
const sagaMiddleware = createSagaMiddleware();

export default createStore(
    rootReducer(history),
    compose(
        applyMiddleware(
            routerMiddleware(history),
            sagaMiddleware,
        ),
        window.__REDUX_DEVTOOLS_EXTENSION__ &&
        (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') ?
        window.__REDUX_DEVTOOLS_EXTENSION__() : f => f
    ),
);

initSagas(sagaMiddleware);