import styled from 'styled-components';

export const Wrapper = styled.div`
    background-color: #00000061;
    position:fixed;
    width: 100%;
    height: 100%;
    z-index: 999;
    display: flex;
    top: 0;
    left: 0;
    justify-content: center;
    align-items: center;
`;

export const Content = styled.div`
    background-color: #ffffff;
    border-radius: 2px;
`;

export const Header = styled.div`
    padding: 0.1em 0.5em;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: #0097A7;
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
`;

export const Title = styled.div`
    color: #ffffff;
    font-size: 1em;
    margin-right: 2em;
`;

export const Button = styled.span`
    cursor: pointer;
`;

export const Body = styled.div`
   flex: 1;
`;