import React from 'react';

import { Wrapper } from './styled';

const Error = ({ message }) => {
    setTimeout(() => {
        window.scrollTo({
            top: document.getElementById('error_message').offsetTop, 
            behavior: 'smooth'
        });
    }, 0);
    return (
        <Wrapper id="error_message">{ message }</Wrapper>
    );
}

export default Error;