import styled, { css } from 'styled-components';

export const Wrapper = styled.button`
    cursor: pointer;
    outline: 0;
    border-radius: 0.2em;
    border: 0.1em solid #0097A7;
    background-color: #ffffff;
    color: #0097A7;
    height: 2.4em;
    min-width: 9em;
    margin-left: 0.5em;
    
    &:hover, &:focus {
        background-color: #E0F7FA          
    }

    &:disabled {
        pointer-events: none;
        border: 0.1em solid #d9d9d9;
        color: #868686;
    }

    ${({ primary }) => primary && css`
        background: #0097A7;
        color: #ffffff;

        &:hover, &:focus {     
            background-color: #00ACC1;    
            border-color: #00ACC1;
        }

        &:disabled {
            background-color: #d9d9d9;
            border: 0.1em solid #d9d9d9;
            color: #868686;
        }
    `}
`