import React, { Component } from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';

import InformetView from '../components/InformetView';
import Modal from '../components/Modal';

class InformetViewContainer extends Component {
    render() {
        const {
            goBack,
            match: { params: { id }}
        } = this.props;
        return (
            <Modal close={ goBack } title=''>
                <InformetView
                    url={ `https://sensores.ccta.mx/api/informet/showPdf/${id}#toolbar=0&navpanes=0&scrollbar=0` }
                />
            </Modal>
        )
    }
}

const mapDispathToProps = dispatch => ({
    goBack: () => dispatch(push('/informes'))
});

const reduxConn = connect(null, mapDispathToProps);

export default reduxConn(InformetViewContainer);