import { take, fork, call, put, cancelled } from 'redux-saga/effects';

import { SIGNIN } from '../constants';
import { authActions } from '../actions';
import { signInApi } from '../api';
import { push } from 'connected-react-router'

function* signIn(field) {
    try {
        const { data, isError, status } = yield call(signInApi.run, field);
        if(isError) throw new Error(status);
        localStorage.setItem('link_token', data);
        yield put(authActions.signinSuccess(data));
        yield put(push('/'));
    } catch (e) {
        yield put(authActions.signinError(e.message));
    } finally {
        if(yield(cancelled())){
            yield call(signInApi.cancel)
        }
    }
}

export function* signInSaga() {
    while(true){
        const { payload: { credentials }} = yield take(SIGNIN.REQUEST);
        yield fork(signIn, credentials);
    }
}