import React, { Component } from 'react';
import { MdClose } from 'react-icons/md';

import { Wrapper, Content, Header, Title, Button, Body } from './styled';

class Modal extends Component {
    constructor(props) {
        super(props);
        this.handleKey = this.handleKey.bind(this);
    }
    componentDidMount() {
        document.addEventListener('keyup', this.handleKey, true)
    }

    componentWillUnmount() {
        document.removeEventListener('keyup', this.handleKey, true)
    }
    handleKey(e) {
        if(e.keyCode === 27) {
            this.props.close();
        }
    }
    render() {
        const { close, title, children } = this.props;
        return (
            <Wrapper onClick={ close }>
                <Content onClick={ e => e.stopPropagation() } >
                    <Header>
                        <Title>{ title }</Title>
                        <Button onClick={ close }>
                            <MdClose
                                size={ 25 }
                                color="#ffffff"
                            />
                        </Button>
                    </Header>
                    <Body>{ children }</Body>
                </Content>
            </Wrapper>
        );
    }
}

export default Modal;