import { createActions } from 'redux-actions';
import moment from 'moment';

import {
    INFORMETS,
    INFORMET_BY_ID,
    INFORMET_PDF_BY_ID,
    SEND_INFORMET_BY_ID,
    INSERT_INFORMET,
    UPDATE_INFORMET
} from '../constants';

export const informetsActions = createActions({
    [INFORMETS.REQUEST]: date => ({ date }),
    [INFORMETS.SUCCESS]: list => ({ list }),
    [INFORMETS.ERROR]: status => ({ status }),
    [INFORMET_BY_ID.REQUEST]: id => ({ id }),
    [INFORMET_BY_ID.SUCCESS]: one => ({ one }),
    [INFORMET_BY_ID.ERROR]: statusOne => ({ statusOne }),
    [INFORMET_PDF_BY_ID.REQUEST]: id => ({ id }),
    [INFORMET_PDF_BY_ID.SUCCESS]: null,
    [INFORMET_PDF_BY_ID.ERROR]: statusPdf => ({ statusPdf }),
    [SEND_INFORMET_BY_ID.REQUEST]: (id, mailList) => ({ id, mailList }),
    [SEND_INFORMET_BY_ID.SUCCESS]: null,
    [SEND_INFORMET_BY_ID.ERROR]: statusSend => ({ statusSend }),
    [INSERT_INFORMET.REQUEST]: informet => ({ informet: {
        ...informet,
        validity_date: moment(informet.validity_date),
        forecasts: informet.forecasts.slice(0, parseInt(informet.valid_days)),
        systems: informet.systems.slice(0, parseInt(informet.valid_days))
    }}),
    [INSERT_INFORMET.SUCCESS]: null,
    [INSERT_INFORMET.ERROR]: insertStatus => ({ insertStatus }),
    [UPDATE_INFORMET.REQUEST]: (informet, id) => ({ informet: {
        ...informet,
        validity_date: moment(informet.validity_date),
        forecasts: informet.forecasts.slice(0, parseInt(informet.valid_days)),
        systems: informet.systems.slice(0, parseInt(informet.valid_days))
    }, id }),
    [UPDATE_INFORMET.SUCCESS]: null,
    [UPDATE_INFORMET.ERROR]: updateStatus => ({ updateStatus }),
});