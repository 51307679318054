import React, { Component } from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { reduxForm } from 'redux-form';

import Modal from '../components/Modal';
import SendInformetForm from '../components/SendInformetForm';
import { informetsActions } from '../actions';

class SendInformetFormContainer extends Component {
    render() {
        const {
            handleSubmit,
            sendInformetByIdRequest,
            goBack,
            isFetchingSend,
            isErrorSend,
            statusSend,
            submitSucceeded
        } = this.props;
        return (
            <Modal close={ goBack } title='Enviar correo'>
                <SendInformetForm 
                    acceptAction={ handleSubmit(sendInformetByIdRequest) }
                    goBack={ goBack }
                    isFetching={ isFetchingSend }
                    isError={ isErrorSend }
                    status={ statusSend }
                    submitSucceeded={ submitSucceeded }
                />
            </Modal>
        )
    }
}

const mapStateToProps = ({ informets: { isFetchingSend, isErrorSend, statusSend }}) => ({
    isFetchingSend,
    isErrorSend,
    statusSend,
})

const mapDispathToProps = (dispatch, { match: { params: { id }}}) => ({
    sendInformetByIdRequest: ({ mailList }) => dispatch(informetsActions.sendInformetByIdRequest(id, mailList)),
    goBack: () => dispatch(push('/informes'))
});

const reduxConn = connect(mapStateToProps, mapDispathToProps);

const formConn = reduxForm({
    form: 'send',
    enableReinitialize: true
})

export default reduxConn(formConn(SendInformetFormContainer));