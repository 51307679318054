import React, { Component } from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';

import { authActions } from '../actions'
import LoginForm from '../components/LoginForm';
import validate from '../components/LoginForm/validate';

class LoginFormContainer extends Component {
    render() {
        const { handleSubmit, signIn, isFetching, isError, status } = this.props;
        return(
            <LoginForm 
                signIn={ handleSubmit(signIn) }
                isFetching={ isFetching }
                isError={ isError }
                status={ status }
            />
        )
    }
}

const mapStateToProps = ({ auth: { isFetching, isError, status }}) => ({
    isFetching,
    isError,
    status,
})

const mapDispatchToProps = dispatch => ({
    signIn: credentials => dispatch(authActions.signinRequest(credentials))
});

const reduxConn = connect(mapStateToProps, mapDispatchToProps);

const formConn = reduxForm({
    form: 'login',
    enableReinitialize: true,
    validate
});

export default reduxConn(formConn(LoginFormContainer));