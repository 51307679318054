import React, { Fragment } from 'react';
import _ from 'lodash';
import moment from 'moment-timezone';
import { MdEdit, MdAdd, MdRemoveRedEye, MdEmail } from 'react-icons/md';
import { FaFilePdf } from 'react-icons/fa';

import { Card } from '../shared/Card';
import { Header, Title, Table, Thead, Th, Td, IconButton } from '../Table/styled';
import Tooltip from '../Tooltip';
import Error from './Error';
import Spinner from '../Spinner';
// import { InputWrapper, Label } from './styled';

const InformetTable = ({
    list,
    isFetching,
    isError,
    informetsRequest,
    newInformet,
    updateInformet,
    getInformetPdf,
    sendInformet
}) => {
    // const[date, setDate] = useState(moment().format('YYYY-MM-DD'));

    // useEffect(() => {
    //     informetsRequest(moment(date, 'YYYY-MM-DD').format('DD-MM-YYYY'));
    // }, [date, informetsRequest]);
    return (
        <Fragment>
            {isFetching ? (
                <Spinner />
            ) : isError ? (
                <Error />
            ) : (
                <Fragment>
                    {/* <InputWrapper>
                        <Label htmlFor="">Fecha de validez:</Label>
                        <input type="date" value={date} onChange={e => setDate(e.target.value)} max={moment().format('YYYY-MM-DD')}/>
                    </InputWrapper> */}
                    <Card>
                        <Header>
                            {/* <Title>{`${_.size(list)} informe`}{_.size(list) !== 1 && 's'}</Title> */}
                            <Title>Informes de hoy</Title>
                            <Tooltip label='Agregar informe' left>
                                <IconButton onClick={ newInformet }>
                                    <MdAdd
                                        size={ 30 }
                                        color="#757575"
                                    />
                                </IconButton>
                            </Tooltip>
                        </Header>
                        <Table>
                            <Thead>
                                <tr>
                                    <Th>Informe</Th>
                                    <Th>Validez</Th>
                                    <Th>Responsable</Th>
                                    <Th>Vigencia</Th>
                                    <Th colSpan='3'>Opciones</Th>
                                </tr>
                            </Thead>
                            <tbody>
                            {_.map(list, ({ _id, title, validity_date, responsable, valid_days }) => ( moment().diff(moment(validity_date), 'days') === 0 &&
                                <tr key={ _id }>
                                    <Td>{ title }</Td>
                                    <Td>{ moment(validity_date).format('DD/MM/YYYY') }</Td>
                                    <Td>{ responsable }</Td>
                                    <Td>{ `${valid_days} días` }</Td>
                                    <Td>
                                        <Tooltip label='Ver informe' left>
                                            <IconButton onClick={ () => window.open(`https://sensores.ccta.mx/api/informet/showPdf/${_id}#toolbar=0&navpanes=0&scrollbar=0`, '_blank') }>
                                            {/* <IconButton onClick={ () => viewInformet(_id) }> */}
                                                <MdRemoveRedEye size={ 20 }/>
                                            </IconButton>
                                        </Tooltip>
                                    </Td>
                                    <Td>
                                        <Tooltip label='Descargar PDF' left>
                                            <IconButton onClick={ () => getInformetPdf(_id) }>
                                                <FaFilePdf size={ 15 }/>
                                            </IconButton>
                                        </Tooltip>
                                    </Td>
                                    <Td>
                                        <Tooltip label='Enviar por correo' left>
                                            <IconButton onClick={ () => sendInformet(_id) }>
                                                <MdEmail size={ 15 }/>
                                            </IconButton>
                                        </Tooltip>
                                    </Td>
                                    <Td>
                                        <Tooltip label='Editar informe' left>
                                            <IconButton onClick={ () => updateInformet(_id) }>
                                                <MdEdit size={ 20 }/>
                                            </IconButton>
                                        </Tooltip>
                                    </Td>
                                </tr>
                            ))}
                            </tbody>
                        </Table>
                    </Card>
                    <br/>
                    <Card>
                        <Header>
                            <Title>Informes antiguos</Title>
                        </Header>
                        <Table>
                            <Thead>
                                <tr>
                                    <Th>Informe</Th>
                                    <Th>Validez</Th>
                                    <Th>Responsable</Th>
                                    <Th>Vigencia</Th>
                                    <Th colSpan='3'>Opciones</Th>
                                </tr>
                            </Thead>
                            <tbody>
                            {_.map(list, ({ _id, title, validity_date, responsable, valid_days }) => ( moment().diff(moment(validity_date), 'days') > 0 &&
                                <tr key={ _id }>
                                    <Td>{ title }</Td>
                                    <Td>{ moment(validity_date).format('DD/MM/YYYY') }</Td>
                                    <Td>{ responsable }</Td>
                                    <Td>{ `${valid_days} días` }</Td>
                                    <Td>
                                        <Tooltip label='Ver informe' left>
                                            <IconButton onClick={ () => window.open(`https://sensores.ccta.mx/api/informet/showPdf/${_id}#toolbar=0&navpanes=0&scrollbar=0`, '_blank') }>
                                            {/* <IconButton onClick={ () => viewInformet(_id) }> */}
                                                <MdRemoveRedEye size={ 20 }/>
                                            </IconButton>
                                        </Tooltip>
                                    </Td>
                                    <Td>
                                        <Tooltip label='Descargar PDF' left>
                                            <IconButton onClick={ () => getInformetPdf(_id) }>
                                                <FaFilePdf size={ 15 }/>
                                            </IconButton>
                                        </Tooltip>
                                    </Td>
                                    <Td>
                                        <Tooltip label='Enviar por correo' left>
                                            <IconButton onClick={ () => sendInformet(_id) }>
                                                <MdEmail size={ 15 }/>
                                            </IconButton>
                                        </Tooltip>
                                    </Td>
                                    <Td>
                                        <Tooltip label='Editar informe' left>
                                            <IconButton onClick={ () => updateInformet(_id) }>
                                                <MdEdit size={ 20 }/>
                                            </IconButton>
                                        </Tooltip>
                                    </Td>
                                </tr>
                            ))}
                            </tbody>
                        </Table>
                    </Card>
                </Fragment>
            )}
        </Fragment>
    );
}

export default InformetTable;