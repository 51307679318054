import React from 'react';

import { Wrapper } from './styled';

const Button = ({ label, action, primary, disabled }) => (
    <Wrapper 
        type='button'
        onClick={ action }
        primary={ primary }
        disabled={ disabled }
    >
        { label }
    </Wrapper>
);

export default Button;