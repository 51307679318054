import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { reducer as form } from 'redux-form';

import informets from './informets';
import auth from './auth';

export default history => combineReducers({
    router: connectRouter(history),
    form,
    auth,
    informets
});