export const windDirectionNormalize = value => {
    if(!value) return value;
    let newValue = value.toUpperCase();
    if(newValue.length === 1) {
        if('N S E O'.includes(newValue)) return newValue;
        return '';
    } else if(newValue.length === 2){
        if('NE NO SE SO NN EN ON ES SS OS'.includes(newValue)) return newValue;
        return newValue.slice(0,1);
    } else if(newValue.length === 3){
        if('NNE ENE ONO NNO ESE SSE SSO OSO'.includes(newValue)) return newValue;
        return newValue.slice(0,2);
    }
    return newValue.slice(0,3);
}