import React from 'react';
import { Field, FormSection } from 'redux-form';

import { Input } from '../../forms';
import { required } from '../../forms/validations';
import { FormRow } from '../styled';
import Panel from '../../Panel';

const SystemForm = ({ number }) => 
    <FormSection name={ `${number}` } >
        <Panel title={ number === 0 ? 'Sistema meteorológico actual' : `Sistema meteorológico a ${number*24} horas` } >
            <FormRow>
                <Field 
                    component={ Input }
                    id='system_condition'
                    label='Condición del sistema'
                    name='system_condition'
                    type='text'
                />
            </FormRow>
            <FormRow>
                <Field 
                    component={ Input }
                    id='system'
                    label='Sistema'
                    name='system'
                    type='text'
                    validate= { required }
                />
                <Field 
                    component={ Input }
                    id='temperatures'
                    label='Temperaturas'
                    name='temperatures'
                    type='text'
                    validate= { required }
                />
            </FormRow>
            <FormRow>
                <Field 
                    component={ Input }
                    id='heaven_condition'
                    label='Condición del cielo'
                    name='heaven_condition'
                    type='text'
                    validate= { required }
                />
                <Field 
                    component={ Input }
                    id='winds'
                    label='Vientos'
                    name='winds'
                    type='text'
                    validate= { required }
                />
            </FormRow>
            <FormRow>
                <Field 
                    component={ Input }
                    id='recommendations'
                    label='Recomendaciones'
                    name='recommendations'
                    type='text'
                />
            </FormRow>
        </Panel>
    </FormSection>

export default SystemForm;