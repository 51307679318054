import axios, { CancelToken } from 'axios';

export const signInApi = {
    cancel: null,
    run: credentials => (
        axios.post('/link/auth/signin', {
            credentials
        }, {
            cancelToken: new CancelToken(c => signInApi.cancel = c),
        })
        .then(({ data }) => data)
    )
};