import React from 'react';

import { Wrapper, PdfContainer } from './styled';

const InformetView = ({ url }) => (
    <Wrapper id='pdf_container'>
        <PdfContainer 
            type='application/pdf'
            aria-label='pdf_viewer'
            data={ url }
        />
    </Wrapper>
);

export default InformetView;