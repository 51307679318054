import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding: 0.5em;
`;

export const Label = styled.label`
    font-size: 0.8em;
    color: #616161;
    margin-bottom: 0.2em;
`;

export const Field = styled.input`
    height: 1.5em;
    width: 100%;
    outline: 0;
    padding: 0;
    border: 1px solid #BDBDBD;
    border-radius: 4px;
    &:focus { 
        border: 0.1em solid #2196F3;
    }
    ${({ isError }) => isError && css`
        border: 0.1em solid #D50000;
    `} 
`;

export const Error = styled.label`
    margin-top: 0.2em;
    font-size: 0.8em;
    color: #D50000;
`;