import styledNormalize from 'styled-normalize';
import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
    ${styledNormalize}

    @font-face {
        font-family: Roboto-Regular;
        src: url('/fonts/Roboto-Regular.otf');
        font-display: swap;
    }

    body,
    #root {
        height: 100vh;
    }

    body {
        background-color: #f5f5f5;
        font-family: 'Roboto-Regular', sans-serif;
        height: 100vh;
    }
`;