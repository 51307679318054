import styled from 'styled-components';

export const Wrapper = styled.div`
    padding: 1em;
    width: 50vw;
`;

export const ButtonsArea = styled.div`
    padding: 0.5em 0.5em 0em 0em;
    display: flex;
    flex-direction: row-reverse;
`;