import React from 'react';
import moment from 'moment-timezone';
import { Field } from 'redux-form';

import { Wrapper, Card, Logo, FormWrapper, Button, Error, Footer, FooterMin } from './styled';
import Input from './Input';

const LoginForm = ({ signIn, isFetching, isError, status }) => (
    <Wrapper>
        <Card>
         <Logo src={require('../../assets/images/logo_login.png')} />
                <FormWrapper >
                    { isError && <Error>{ status }</Error>}
                    <Field 
                        name='username'
                        type='text'
                        label='Usuario'
                        component={ Input }
                    />
                    <Field 
                        name='password'
                        type='password'
                        label='Contraseña'
                        component={ Input }
                    />
                    <Button disabled={isFetching} onClick={ signIn }>Iniciar sesión</Button>
                </FormWrapper>
        </Card>
        <Footer>Ⓒ {moment().format('YYYY')} CreaAS Desarrollos Informáticos - Todos los derechos reservados.</Footer>
        <FooterMin>Ⓒ {moment().format('YYYY')} CreaAS Desarrollos Informáticos</FooterMin>
    </Wrapper>
);

export default LoginForm;