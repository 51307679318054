import React, { Component } from 'react';
import { connect } from 'react-redux';

import Header from '../components/Header';
import { authActions } from '../actions'

class HeaderContainer extends Component {
    render() {
        const { logout } = this.props;
        return (
            <Header 
                logout={ logout }
            />
        );
    }
}

const mapDispatchToProps = dispatch => ({
    logout: () => dispatch(authActions.logout()),
});

const reduxConn = connect(null, mapDispatchToProps);

export default reduxConn(HeaderContainer);