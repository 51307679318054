import { handleActions } from 'redux-actions';
import Immutable from 'seamless-immutable';
import _ from 'lodash';

import {
    INFORMETS,
    INFORMET_BY_ID,
    INFORMET_PDF_BY_ID,
    SEND_INFORMET_BY_ID,
    INSERT_INFORMET,
    UPDATE_INFORMET,
} from '../constants';

const initialState = Immutable({
    list: {},
    one: {},
    isFetchingList: false,
    isErrorList: false,
    statusList: null,
    isFetchingOne: false,
    isErrorOne: false,
    statusOne: null,
    isFetchingPdf: false,
    isErrorPdf: false,
    statusPdf: null,
    isFetchingSend: false,
    isErrorSend: false,
    statusSend: null,
    isFetchingInsert: false,
    isErrorInsert: false,
    insertStatus: null,
    isFetchingUpdate: false,
    isErrorUpdate: false,
    updateStatus: null,
});

const informets = handleActions({
    [INFORMETS.REQUEST]: state => state.merge({ isFetchingList: true, isErrorList: false, statusList: null }),
    [INFORMETS.SUCCESS]: (state, { payload: { list } }) => state.merge({ isFetchingList: false, isErrorList: false, statusList: null, list: _.keyBy(list, '_id') }),
    [INFORMETS.ERROR]: (state, { payload: { statusList }}) => state.merge({ isFetchingList: false, isErrorList: true, statusList }),
    [INFORMET_BY_ID.REQUEST]: state => state.merge({ isFetchingOne: true, isErrorOne: false, statusOne: null }),
    [INFORMET_BY_ID.SUCCESS]: (state, { payload: { one } }) => state.merge({ isFetchingOne: false, isErrorOne: false, statusOne: null, one }),
    [INFORMET_BY_ID.ERROR]: (state, { payload: { statusOne }}) => state.merge({ isFetchingOne: false, isErrorOne: true, statusOne }),
    [INFORMET_PDF_BY_ID.REQUEST]: state => state.merge({ isFetchingPdf: true, isErrorPdf: false, statusPdf: null }),
    [INFORMET_PDF_BY_ID.SUCCESS]: state => state.merge({ isFetchingPdf: false, isErrorPdf: false, statusPdf: null }),
    [INFORMET_PDF_BY_ID.ERROR]: (state, { payload: { statusPdf }}) => state.merge({ isFetchingPdf: false, isErrorPdf: true, statusPdf }),
    [SEND_INFORMET_BY_ID.REQUEST]: state => state.merge({ isFetchingSend: true, isErrorSend: false, statusSend: null }),
    [SEND_INFORMET_BY_ID.SUCCESS]: state => state.merge({ isFetchingSend: false, isErrorSend: false, statusSend: null }),
    [SEND_INFORMET_BY_ID.ERROR]: (state, { payload: { statusSend }}) => state.merge({ isFetchingSend: false, isErrorSend: true, statusSend }),
    [INSERT_INFORMET.REQUEST]: state => state.merge({ isFetchingInsert: true, isErrorInsert: false, insertStatus: null }),
    [INSERT_INFORMET.SUCCESS]: state => state.merge({ isFetchingInsert: false, isErrorInsert: false, insertStatus: null }),
    [INSERT_INFORMET.ERROR]: (state, { payload: { insertStatus }}) => state.merge({ isFetchingInsert: false, isErrorInsert: true, insertStatus }),
    [UPDATE_INFORMET.REQUEST]: state => state.merge({ isFetchingUpdate: true, isErrorUpdate: false, updateStatus: null }),
    [UPDATE_INFORMET.SUCCESS]: state => state.merge({ isFetchingUpdate: false, isErrorUpdate: false, updateStatus: null }),
    [UPDATE_INFORMET.ERROR]: (state, { payload: { updateStatus }}) => state.merge({ isFetchingUpdate: false, isErrorUpdate: true, updateStatus }),
}, initialState);

export default informets;