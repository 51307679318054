import React, { Component } from 'react';
import { connect } from 'react-redux';
import { reduxForm, getFormValues } from 'redux-form';
import { push } from 'connected-react-router';

import InformetUpdateForm from '../components/InformetUpdateForm';
import { getInformet } from '../selectors/informets';
import { informetsActions } from '../actions';


class InformetUpdateFormContainer extends Component {
    componentDidMount() {
        this.props.informetByIdRequest();
    }
    render() {
        const {
            handleSubmit,
            goBack,
            isFetching,
            isError,
            status,
            submitSucceeded,
            formValues,
            updateInformetRequest,
            insertInformetRequest,
        } = this.props;
        return(
            <InformetUpdateForm 
                title='Modificar Informe Meteorológico'
                update={ handleSubmit(updateInformetRequest) }
                create={ handleSubmit(insertInformetRequest) }
                goBack={ goBack }
                acceptLabel='Guardard'
                isFetching={ isFetching }
                isError={ isError }
                status={ status }
                submitSucceeded={ submitSucceeded }
                formValues={ formValues }
            />
        )
    }
}

const mapStateToProps = (state, { match: { params: { id }}}) => ({
    formValues: getFormValues('informet')(state),
    initialValues: getInformet(state, id),
    isFetching: state.informets.isFetchingUpdate,
    isError: state.informets.isErrorUpdate,
    status: state.informets.updateStatus,
});

const mapDispatchToProps = (dispatch, { match: { params: { id }}}) => ({
    goBack: () => dispatch(push('/informes')),
    informetByIdRequest: () => dispatch(informetsActions.informetByIdRequest(id)),
    updateInformetRequest: informet => dispatch(informetsActions.updateInformetRequest(informet, id)),
    insertInformetRequest: informet => dispatch(informetsActions.insertInformetRequest(informet)),
})

const reduxConn = connect(mapStateToProps, mapDispatchToProps);

const formConn = reduxForm({
    form: 'informet',
    enableReinitialize: true
});

export default reduxConn(formConn(InformetUpdateFormContainer));