import React from 'react';

import { Wrapper, Logo, LogoutButton } from './styled';

const Header = ({ logout }) => (
    <Wrapper>
        <Logo src={ require('../../assets/images/ccta.png')}/>
        <LogoutButton onClick={ () => {
            window.localStorage.removeItem('link_token');
            logout();
        } }>Salir</LogoutButton>
        {/* <p>CCTA</p> */}
    </Wrapper>
);

export default Header;