import React from 'react';
import { Field } from 'redux-form';

import { Input } from '../forms';
import { required, multiEmail } from '../forms/validations';
import Button from '../Button';
import Error from '../Error';
import { Wrapper, ButtonsArea }  from './style';

const SendInformetForm = ({
    acceptAction,
    goBack,
    isFetching,
    isError,
    status,
    submitSucceeded
}) => (
    <Wrapper>
        { submitSucceeded && isError && <Error message={ status } />}
        <Field 
            component={ Input }
            id='mailList'
            label='Destinatarios'
            name='mailList'
            type='text'
            validate= { [required, multiEmail] }
        />
        <ButtonsArea>
            <Button 
                label='Enviar'
                action={ acceptAction }
                primary
                disabled={ isFetching }
            />
            <Button 
                label='Cancelar'
                action={  goBack }
                disabled={ isFetching }
            />
        </ButtonsArea>
    </Wrapper>
);

export default SendInformetForm;