import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0.5em;
`;

export const Label = styled.label`
    font-size: 0.8em;
    color: #616161;
    margin-bottom: 0.2em;
`;

export const Icon = styled.img`
    width: 5em;
    height: auto;
`;

export const Image = styled.img`
    max-width: 100%;
    max-height: 100%;
`;

export const ImageWrapper = styled.div`
    border: 0.1em dashed #BDBDBD;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 15em;
    cursor: pointer;
    ${({ isError }) => isError && css`
        border: 0.1em dotted #D50000;
    `} 
`;

export const Field = styled.input`
    display: none;
`;

export const Error = styled.label`
    margin-top: 0.2em;
    font-size: 0.8em;
    color: #D50000;
`;