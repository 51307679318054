import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
    position: relative;
    display: inline-block;
`;

export const Text = styled.span`
    visibility: hidden;
    background-color: #757575;
    color: #fff;
    text-align: center;
    padding: 0.5em 0.4em 0.4em 0.4em;
    border-radius: 2px;
    font-size: 0.7em;
    white-space: nowrap;
    position: absolute;
    z-index: 1;

    ${Wrapper}:hover & {
        visibility: visible;
    }

    ${({ top }) => top && css`
        width: 120px;
        bottom: 100%;
        left: 50%; 
        margin-left: -60px;
    `}

    ${({ right }) => right && css`
        top: -5px;
        left: 105%; 
    `}

    ${({ bottom }) => bottom && css`
        width: 120px;
        top: 100%;
        left: 50%; 
        margin-left: -60px;
    `}

    ${({ left }) => left && css`
        top: -5px;
        right: 105%;  
    `}
`;