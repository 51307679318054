import React from 'react';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router';

const PrivateRoute = ({ component: Component, isAuth, ...rest }) => (
    <Route
        { ...rest }
        render={ props => 
            isAuth ? (
                <Component { ...props }/>
            ) : (
                <Redirect to="/login" />
            )
        }
    />
);

const mapStateToProps = ({ auth: { isAuth }}) => ({
    isAuth,
});

const reduxConnector = connect(mapStateToProps, null);

export default reduxConnector(PrivateRoute);