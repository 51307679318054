import React from 'react';
import { Wrapper, Text } from './styled';

const Tooltip = ({ label, children, ...props }) => (
    <Wrapper>
        <Text { ...props }>{ label }</Text>
        { children }
    </Wrapper>
);

export default Tooltip;
