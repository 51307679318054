import React from 'react';
import { Field, FormSection } from 'redux-form';

import { Input } from '../../forms';
import { required, windDirValid } from '../../forms/validations';
import { decimalNormalize, windDirectionNormalize } from '../../forms/normalizers';
import { FormRow } from '../styled';
import Panel from '../../Panel';

const ForecastForm = ({ number }) => 
    <FormSection name={ `${number}` } >
        <Panel title={ number === 0 ? 'Pronóstico actual' : `Pronóstico a ${number*24} horas` }>
            <FormRow>
                <Field 
                    component={ Input }
                    id='weather_condition'
                    label='Condición climatológica'
                    name='weather_condition'
                    type='text'
                    validate= { required }
                />
            </FormRow>
            <FormRow>
                <Field 
                    component={ Input }
                    id='temp_high'
                    label='Temperatura máxima (ºC)'
                    name='temp_high'
                    type='text'
                    validate= { required }
                    normalize={ decimalNormalize(true, 2, 2) }
                />
                <Field 
                    component={ Input }
                    id='temp_low'
                    label='Temperatura mínima (ºC)'
                    name='temp_low'
                    type='text'
                    validate= { required }
                    normalize={ decimalNormalize(true, 2, 2) }
                />
                <Field 
                    component={ Input }
                    id='rainfall'
                    label='Lluvia (mm)'
                    name='rainfall'
                    type='text'
                    validate= { required }
                    normalize={ decimalNormalize(false, 3, 2) }
                />
                <Field 
                    component={ Input }
                    id='wind_speed'
                    label='Viento (km/h)'
                    name='wind_speed'
                    type='text'
                    validate= { required }
                    normalize={ decimalNormalize(false, 3, 2) }
                />
                <Field 
                    component={ Input }
                    id='wind_direction'
                    label='Dirección del viento'
                    name='wind_direction'
                    type='text'
                    validate= { [required, windDirValid] }
                    normalize={ windDirectionNormalize }
                />
            </FormRow>
        </Panel>
    </FormSection>

export default ForecastForm;