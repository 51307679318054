import styled from 'styled-components';

export const Wrapper = styled.div`
    background-color: #5c6063;
    height: calc(100vh - 5em);
    width: 80vw;
    overflow-x: scroll;
    overflow-y: scroll;
`;

export const PdfContainer = styled.object`
    width: 100%;
    height: 100%;
`;

export const Page = styled.div`
    display: flex;
    flex-direction: column;
    width: 697px;
    height: 1148px;
    background-color: #ffffff;
    font-family: Arial, Helvetica, sans-serif;
`;

export const Header = styled.div`
    background-color: #388E3C;
    padding: 16px 18px 5px 18px;
`;

export const Title = styled.div`
    font-size: 16px;
    color: #ffffff;
    margin-bottom: 10px;
`;

export const Label = styled.div`
    font-size: 12px;
    color: #ffffff;
    margin-bottom: 5px;
`;

export const Body = styled.div`
    padding: 5px 18px 16px 18px;
`;

export const ImageArea = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 5px 0px;
`;

export const ImageWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    height: 205px;
    width: 49%;
`;

export const Image = styled.img`
    max-width: 100%;
    max-height: 90%;
    margin-bottom: 5px;
`;

export const Caption = styled.div`
    font-size: 12px;
    font-weight: bold;
`;

export const Panel = styled.div`
    margin: 10px 0px;
`;

export const PanelTitle = styled.div`
    background-color: #757575;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    color: #ffffff;
    padding: 5px;
    font-size: 14px;
    text-align: center;
`;

export const PanelBody = styled.div`
    border: 1px solid #757575;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
`;

export const PanelText = styled.div`
    padding: 10px;
    font-size: 12px;
    text-align: justify;
`;

export const Table = styled.table`
    width: 100%;
    border-collapse: collapse;
    font-size: 12px;
`;

export const Thead = styled.thead``;

export const Tbody = styled.tbody``;

export const Tr = styled.tr`
    border-bottom: 1px solid #757575;
    &:last-child{
        border-bottom: none;
    }
    &:first-child {
        border-bottom: 1px solid #757575;
    }
`;

export const Th = styled.th`
    padding: 6px 0px 6px 0px;
`;

export const Td = styled.td`
    padding: 4px;
    text-align: center; 
`;

export const Footer = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 0px 18px 16px 18px;
`
export const Signature = styled.div`
    text-align: right;
    font-size: 11px;
    color: #9E9E9E;
    margin-bottom: 1px;
`;

export const Lines = styled.div`
    border-top: 4px solid #6FA1Cf;
    border-bottom: 4px solid #D8DEA6;
`;
