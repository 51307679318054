import React, { Component } from 'react';
import { connect } from 'react-redux';
import { reduxForm, getFormValues } from 'redux-form';
import { push } from 'connected-react-router';

import InformetForm from '../components/InformetForm';
import { getInformet } from '../selectors/informets';

class InformetFormContainer extends Component {
    render() {
        const {
            title,
            handleSubmit,
            acceptAction,
            goBack,
            acceptLabel,
            isFetching,
            isError,
            status,
            submitSucceeded,
            formValues,
        } = this.props;
        return(
            <InformetForm 
                title={ title }
                acceptAction={ handleSubmit(acceptAction) }
                goBack={ goBack }
                acceptLabel={ acceptLabel }
                isFetching={ isFetching }
                isError={ isError }
                status={ status }
                submitSucceeded={ submitSucceeded }
                formValues={ formValues }
            />
        )
    }
}

const mapStateToProps = (state, { id }) => ({
    formValues: getFormValues('informet')(state),
    initialValues: getInformet(state, id),
});

const mapDispatchToProps = dispatch => ({
    goBack: () => dispatch(push('/informes')),
})

const reduxConn = connect(mapStateToProps, mapDispatchToProps);

const formConn = reduxForm({
    form: 'informet',
    enableReinitialize: true
});

export default reduxConn(formConn(InformetFormContainer));