import React from 'react';

import { Wrapper, Filed, Error } from './styled';

const Input = ({ type, label, input, meta: { error, touched } }) => (
    <Wrapper>
        <Filed type={ type } {...input} didError={ error && touched } placeholder={label}/>
        {touched && error && <Error>{error}</Error>}
    </Wrapper>
);

export default Input;