import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
    padding: 10px 30px;
`;

export const Label = styled.div`
    margin-bottom: 0.4em;
`;

export const Filed = styled.input`
    height: 2em;
    padding: 4px;
    width: calc(100% - 4px);
    border: 1px solid #FAFAFA;
    border-radius: 2px;
    outline:0;
    &:focus { 
        border: 1px solid #2196F3;
    }
    ${({ didError }) => didError && css`
        border: 1px solid #E57373;
    `}
`;

export const Error = styled.div`
    text-align: left;
    color: #E57373;
    font-size: 0.8em;
    margin-top: 0.2em;
`;