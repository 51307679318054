import { createSelector } from 'reselect';
import moment from 'moment-timezone';
// import ver from '../assets/data/informetVer';

export const getInformet = createSelector(
    ({ informets }, id) => ({ informets, id }),
    ({ informets, id }) => {
        if(informets.one && id && informets.one._id === id) return {
            ...informets.one.without(['_id']),
            validity_date: moment(informets.one.validity_date).format('YYYY-MM-DD')
        }
        return {
            responsable: 'L.C.A. Pablo Reyes Murillo',
            valid_days: '3'
        }
        // return ver;
    }
);

export const getFileName = createSelector(
    ({ informets }, id) => informets.list[id],
    ({ zone, validity_date}) => `${zone.toLowerCase()}-${moment(validity_date).format('DD-MM-YYYY')}.pdf`
)