import styled from 'styled-components';

export const Wrapper = styled.footer`
    background-color: #ffffff;
    height: 3.5em;
    position: relative;
    color: #000000;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-top: 0.05em solid #dadada;
`;

export const Text = styled.div`
    font-size: 0.8em;
    text-align: center;
`;