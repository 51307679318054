import React from 'react';
import Root from '../routes';
import GlobalStyle from '../../styles';

import Header from '../../containers/HeaderContainer';
import Footer from '../Footer';
import { Wrapper, Content } from './styled';

export default () => (
    <Wrapper>
        <GlobalStyle />
        <Header />
        <Content>
            <Root/>
        </Content>
        <Footer />
    </Wrapper>
);