import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import moment from 'moment-timezone';
import axios from 'axios';

import store, { history } from './store';
import App from '../src/components/App';

moment.tz.setDefault('America/Mexico_City');
axios.defaults.baseURL = 'https://sensores.ccta.mx';

ReactDOM.render((
    <Provider store={ store }>
        <ConnectedRouter history={ history }>
            <App />
        </ConnectedRouter>
    </Provider>
), document.getElementById('root'));