import styled from 'styled-components';

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 1em;
`;

export const Image = styled.img`
    width: 15em;
    align-self: center;
`;

export const Label = styled.div`
    text-align: center;
    font-size: 3em;
    font-weight: 500;
    margin-bottom: 0.75em;
`;

export const Message = styled.div`
    font-size: 1em;
    color: #6c6b70;
    margin-bottom: 20px;
    text-align: center;
`;