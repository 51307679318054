import React from 'react';
import { Switch, Route, Redirect } from 'react-router';

import PrivateRoute from '../../containers/PrivateRoute';
import LoginFormContainer from '../../containers/LoginFormContainer';
import Informes from './Informes';
import InformetNewFormContainer from '../../containers/InformetNewFormContainer';
import InformetUpdateFormContainer from '../../containers/InformetUpdateFormContainer'

const Root = () => (
    <Switch>
        <Route exact path='/login' component={ LoginFormContainer } />
        <PrivateRoute path='/informes' component={ Informes } />
        <PrivateRoute exact path='/nuevo' component={ InformetNewFormContainer } />
        <PrivateRoute exact path='/modificar/:id' component={ InformetUpdateFormContainer } />
        <Redirect to='/informes' />
    </Switch>
);

export default Root;