import React from 'react';
import { Field, FormSection } from 'redux-form';
import _ from 'lodash';

import { Input, ImageInput } from '../forms';
import { required } from '../forms/validations';
import { integerNormalize } from '../forms/normalizers'
import Button from '../Button';
import ForecastForm from './ForecastForm';
import SystemForm from './SystemForm';
import { Wrapper, FormRow, ButtonsArea } from './styled';

import Panel from '../Panel';
import Error from '../Error';

const InformetForm = ({
    title,
    goBack,
    isFetching,
    isError,
    status,
    submitSucceeded,
    formValues,
    update,
    create,
}) => (
    <Wrapper>
        <h3 align='center'>{ title }</h3>
        { submitSucceeded && isError && <Error message={ status } />}
        <Panel title='Boletin'>
            <FormRow>
                <Field 
                    component={ Input }
                    id='title'
                    label='Título'
                    name='title'
                    type='text'
                    validate= { required }
                />
            </FormRow>
            <FormRow>
                <Field 
                    component={ Input }
                    id='validity_date'
                    label='Fecha de validez'
                    name='validity_date'
                    type='date'
                    validate= { required }
                />
                <Field 
                    component={ Input }
                    id='responsable'
                    label='Responsable'
                    name='responsable'
                    type='text'
                    validate= { required }
                />
            </FormRow>
            <FormRow>
                <Field 
                    component={ Input }
                    id='zone'
                    label='Zona'
                    name='zone'
                    type='text'
                    validate= { required }

                />
                <Field 
                    component={ Input }
                    id='valid_days'
                    label='Días de vigencia'
                    name='valid_days'
                    type='text'
                    validate= { required }
                    normalize={ integerNormalize(1) }
                />
            </FormRow>
            <FormRow>
                <Field 
                    component={ ImageInput }
                    id='forecast_image'
                    label='Imagen del pronóstico'
                    name='forecast_image'
                    type='file'
                    accept='image/*'
                    src={ formValues && formValues.forecast_image  }
                    validate= { required }
                />
                <Field 
                    component={ ImageInput }
                    id='system_image'
                    label='Imagen del sistema'
                    name='system_image'
                    type='file'
                    accept='image/*'
                    src={ formValues && formValues.system_image  }
                    validate= { required }
                />
            </FormRow>
        </Panel>
        <FormSection name="forecasts">
        { formValues && formValues.valid_days && _.range(0, parseInt(formValues.valid_days)).map(key =>
            <ForecastForm 
                number={ key }
                key={ key } 
            />
        )}
        </FormSection>
        <FormSection name="systems">
        { formValues && formValues.valid_days && _.range(0, parseInt(formValues.valid_days)).map(key =>
            <SystemForm 
                number={ key }
                key={ key } 
            />
        )}
        </FormSection>
        <Panel title='Recomendaciones generales'>
            <FormRow>
                <Field 
                    component={ Input }
                    id='recommendations'
                    label='Recomendaciones'
                    name='recommendations'
                    type='text'
                    validate= { required }
                />
            </FormRow>
        </Panel>
        <ButtonsArea>
            <Button 
                label='Guardar como nuevo'
                action={ create }
                primary
                disabled={ isFetching }
            />
            <Button 
                label='Actualizar'
                action={ update }
                primary
                disabled={ isFetching }
            />
            <Button 
                label='Cancelar'
                action={  goBack }
                disabled={ isFetching }
            />
        </ButtonsArea>
    </Wrapper>
);

export default InformetForm;