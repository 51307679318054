import styled from 'styled-components';

export const Wrapper = styled.header`
    background-color: #ffffff;
    height: 3.5em;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-bottom: 0.05em solid #dadada;
`;

export const Logo = styled.img`
    height: 70%;
    padding: 1em;
`;

export const LogoutButton = styled.div`
    font-size: 12px;
    border: 2px solid #000;
    padding: 5px 20px;
    border-radius: 5px;
    margin-right: 1em;
    cursor: pointer;
    &:hover {
        background-color: #E0E0E0;
    }
`;