import React from 'react';

import { Wrapper, Circle } from './styled';

const Spinner = () => (
    <Wrapper>
        <Circle />
    </Wrapper>
);

export default Spinner;