import React, { Component } from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';

import InformetFormContainer from './InformetFormContainer';
import { informetsActions } from '../actions';

class InformetNewFormContainer extends Component {
    render() {
        const { 
            insertInformetRequest,
            isFetchingInsert,
            isErrorInsert,
            insertStatus,
        } = this.props;
        return(
            <InformetFormContainer 
                title='Nuevo Informe Meteorológico'
                acceptAction={ insertInformetRequest }
                acceptLabel='Guardar'
                isFetching={ isFetchingInsert }
                isError={ isErrorInsert }
                status={ insertStatus }
            />
        )
    }
}

const mapStateToProps = ({ informets: { isFetchingInsert, isErrorInsert, insertStatus }}) => ({
    isFetchingInsert,
    isErrorInsert,
    insertStatus,
});

const mapDispatchToProps = dispatch => ({
    insertInformetRequest: informet => dispatch(informetsActions.insertInformetRequest(informet)),
});

const reduxConn = connect(mapStateToProps, mapDispatchToProps);

const formConn = reduxForm({
    form: 'informet',
    enableReinitialize: true
});

export default reduxConn(formConn(InformetNewFormContainer));