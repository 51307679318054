import styled from 'styled-components';

export const Wrapper = styled.form`
    padding: 1em;
    background-color: #f5f5f5;
`;

export const FormRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;

export const ButtonsArea = styled.div`
    display: flex;
    flex-direction: row-reverse;
`;