import React from 'react';

import { Wrapper, Title, Content } from './styled';

const Panel = ({ title, children }) => (
    <Wrapper>
        <Title>{ title }</Title>
        <Content>{ children }</Content>
    </Wrapper>
);

export default Panel;