import React from 'react';
import moment from 'moment-timezone';

import { Wrapper, Text } from './styled';

const Footer = () => (
    <Wrapper>
        <Text>Ⓒ {moment().format('YYYY')} ccta.mx Desarrollos Informáticos - Todos los derechos reservados.</Text>
    </Wrapper>
);

export default Footer;