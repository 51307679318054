import styled from 'styled-components';

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

export const Content = styled.div`
    min-height: calc(100vh - 7em);
    position: relative;
`;