import { take, takeLatest, fork, call, put, cancelled, cancel, select } from 'redux-saga/effects';
import { LOCATION_CHANGE, push } from 'connected-react-router';
import FileDownload from 'js-file-download';

import errorMsg from '../utility/errorMsg';
import { informetsActions } from '../actions';
import { getFileName } from '../selectors/informets';
import {
    INFORMETS,
    INFORMET_BY_ID,
    INFORMET_PDF_BY_ID,
    SEND_INFORMET_BY_ID,
    INSERT_INFORMET,
    UPDATE_INFORMET,
} from '../constants';

import {
    fetchInformetsApi,
    fetchInformetByIdApi,
    fetchInformetPdfByIdApi,
    fetchSendInformetByIdApi,
    insertInformetApi,
    updateInformetApi,
} from '../api';

function* fetchInformets({ payload: { date }}) {
    try {
        const { data, isError, status } = yield call(fetchInformetsApi.run, date);
        if(isError) throw new Error(status);
        yield put(informetsActions.informetsSuccess(data));
    } catch (error) {
        yield put(informetsActions.informetsError(errorMsg(error)));
    } finally {
        if(yield cancelled()) yield call(fetchInformetsApi.cancel);
    }
}

export function* fetchInformetsSaga() {
    while(true) {
        const task = yield takeLatest(INFORMETS.REQUEST, fetchInformets);
        const { payload: { pathname }} = yield take(LOCATION_CHANGE);
        if(pathname !== '/') yield cancel(task);
    }
}

function* fetchInformetById(id) {
    try {
        const { data, isError, status } = yield call(fetchInformetByIdApi.run, id);
        if(isError) throw new Error(status);
        yield put(informetsActions.informetByIdSuccess(data));
    } catch (error) {
        yield put(informetsActions.informetByIdError(errorMsg(error)));
    } finally {
        if(yield cancelled()) yield call(fetchInformetByIdApi.cancel);
    }
}

export function* fetchInformetByIdSaga() {
    while(true){
        const { payload: { id }} = yield take(INFORMET_BY_ID.REQUEST);
        const task = yield fork(fetchInformetById, id);
        const { payload: { pathname }} = yield take(LOCATION_CHANGE);
        if(pathname !== `/modificar/${id}`) yield cancel(task);
    }
}

function* fetchInformetPdfById(id) {
    try {
        const { data } = yield call(fetchInformetPdfByIdApi, id);
        const fileName = yield select(getFileName, id)
        FileDownload(data, fileName);
        yield put(informetsActions.informetPdfByIdSuccess());
    } catch (error) {
        yield put(informetsActions.informetPdfByIdError(errorMsg(error)));
    }
}

export function* fetchInformetPdfByIdSaga() {
    while(true){
        const { payload: { id }} = yield take(INFORMET_PDF_BY_ID.REQUEST);
        yield fork(fetchInformetPdfById, id);
    }
}

function* fetchSendInformetById(id, mailList) {
    try {
        const { isError, status } = yield call(fetchSendInformetByIdApi.run, id, mailList);
        if(isError) throw new Error(status);
        yield put(informetsActions.sendInformetByIdSuccess());
        yield put(push('/informes'));
    } catch (error) {
        yield put(informetsActions.sendInformetByIdError(errorMsg(error)));
    } finally {
        if(yield cancelled()) yield call(fetchSendInformetByIdApi.cancel);
    }
}

export function* fetchSendInformetByIdSaga() {
    while(true){
        const { payload: { id, mailList }} = yield take(SEND_INFORMET_BY_ID.REQUEST);
        const task = yield fork(fetchSendInformetById, id, mailList);
        const { payload: { pathname }} = yield take(LOCATION_CHANGE);
        if(pathname !== `/enviar/${id}`) yield cancel(task);
    }
}

function* insertInformet({ payload: { informet }}) {
    try {
        const { isError, status } = yield call(insertInformetApi.run, informet);
        if(isError) throw new Error(status);
        yield put(informetsActions.insertInformetSuccess());
        yield put(push('/informes'));
    } catch (error) {
        yield put(informetsActions.insertInformetError(errorMsg(error)));
    } finally {
        if(yield cancelled()) yield call(insertInformetApi.cancel);
    }
}

export function* insertInformetSaga() {
    while(true) {
        const task = yield takeLatest(INSERT_INFORMET.REQUEST, insertInformet);
        const { payload: { pathname }} = yield take(LOCATION_CHANGE);
        if(pathname !== '/nuevo') yield cancel(task);
    }
}

function* updateInformet({ payload: { informet, id }}) {
    try {
        const { isError, status } = yield call(updateInformetApi.run, informet, id);
        if(isError) throw new Error(status);
        yield put(informetsActions.updateInformetSuccess());
        yield put(push('/informes'));
    } catch (error) {
        yield put(informetsActions.updateInformetError(errorMsg(error)));
    } finally {
        if(yield cancelled()) yield call(updateInformetApi.cancel);
    }
}

export function* updateInformetSaga() {
    while(true) {
        const task = yield takeLatest(UPDATE_INFORMET.REQUEST, updateInformet);
        yield take(LOCATION_CHANGE);
        yield cancel(task);
    }
}