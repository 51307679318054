import React from 'react';

import { Wrapper, Image, Label, Message } from './styled';

const Error = () => (
    <Wrapper>
        <Image src={require('../../../assets/images/cloud_error.svg')}/>
        <Label>¡Ups!</Label>
        <Message>Lo sentimos, pero no podemos procesar tu solicitud. Inténtalo más tarde.</Message>
    </Wrapper>
);

export default Error;