import { handleActions } from 'redux-actions';
import Immutable from 'seamless-immutable';

import { SIGNIN } from '../constants';

const initialState = () => {
    const token = localStorage.getItem('link_token');
    return Immutable({
        isAuth: token ? true : false,
        token: token ? token : null,
        isFetching: false,
        isError: false,
        status: null
    })
}

export default handleActions({
    [SIGNIN.REQUEST]: state => state.merge({ isFetching: true, isError: false, }),
    [SIGNIN.SUCCESS]: (state, { payload: { token }}) => state.merge({ isFetching: false, token, isAuth: true }),
    [SIGNIN.ERROR]: (state, { payload: { status }}) => state.merge({ isFetching: false, isError: true, status }),
    logout: state => state.merge({ isAuth: false, token: null }),
}, initialState());