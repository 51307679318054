import React from 'react';
import { Switch, Route, Redirect } from 'react-router';


import InformetTableContainer from '../../containers/InformetTableContainer';
import InformetViewContainer from '../../containers/InformetViewContainer';
import SendInformetFormContainer from '../../containers/SendInformetFormContainer';

const Informes = () => (
    <div>
        <InformetTableContainer />
        <Switch>
            <Route exact path='/informes/visualizar/:id' component={ InformetViewContainer }/>
            <Route exact path='/informes/enviar/:id' component={ SendInformetFormContainer }/>
            <Route exact path='/informes/*' component={ () => <Redirect to='/' /> }/>
        </Switch>
    </div>
);

export default Informes;