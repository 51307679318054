import styled from 'styled-components';
import img from '../../assets/images/login.jpg';

export const Wrapper = styled.div`
    position: fixed;
    z-index: 999;
    top: 0;
    height: 100vh;
    width: 100vw;
    background-image: 
    linear-gradient(
      rgba(0, 0, 0, 0.4),
      rgba(0, 0, 0, 0.4)
    ),
    url(${img});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const Card = styled.div`
    margin-top: 10%;
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 2px;
    backdrop-filter: blur(7px);  
    -webkit-backdrop-filter: blur(7px);
    width: 350px;
    height: 450px;
    text-align: center;
`;

export const Logo = styled.img`
    width: 160px;
    margin-top: 30px;
    margin-bottom: 30px;
`;
export const FormWrapper = styled.form`
    display: flex;
    flex-direction: column;
`;

export const Button = styled.button`
    cursor: pointer;
    margin-top: 10px;
    height: 2.6em;
    width: auto;
    margin-left: 30px;
    margin-right: 25px;
    border-radius: 2px;
    outline:0;
    border: none;
    background: #6a9ecd;
    color: white;
    &:hover {
        background: #51799e;
    }
    &:active {
        background: #51799e;
    }
`;

export const Error = styled.div`
    padding: 0.5em;
    margin-left: 30px;
    margin-right: 25px;
    margin-bottom: 1em;
    text-align: center;
    border-radius: 2px;
    border: 1px solid #E57373;
    background: #b71c1c1f;
    color: #E57373;
`;

export const Footer = styled.div`
    color: #fff;
    text-rendering: optimizelegibility;
    text-shadow: 0 0 3px rgba(0, 0, 0, .8);
    position: absolute;
    bottom: 0;
    margin-bottom: 10px;
    @media (max-width: 600px) {
        display: none;
    }
`;

export const FooterMin = styled.div`
    color: #fff;
    text-rendering: optimizelegibility;
    text-shadow: 0 0 3px rgba(0, 0, 0, .8);
    position: absolute;
    bottom: 0;
    margin-bottom: 10px;
    @media (min-width: 600px) {
        display: none;
    }
`;