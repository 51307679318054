import React from 'react';

import { Wrapper, Label, Field, Error } from './styled';

export const Input = ({
    input, 
    type, 
    label, 
    id, 
    meta: { touched, error }
}) => (
    <Wrapper>
        <Label htmlFor={ id }>{ label }</Label>
        <Field 
            { ...input }
            type={ type }
            id={ id }
            isError={ error && touched }
        />
        { touched && error && 
        <Error>{ error }</Error>}
    </Wrapper>
);