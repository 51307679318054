import axios, { CancelToken } from 'axios';

export const fetchInformetsApi = {
    cancel: null,
    run: date => (
        axios.get('/api/informet/getAllLatest', {
            cancelToken: new CancelToken(c => fetchInformetsApi.cancel = c),
            params: { date },
        })
        .then(({ data }) => data)
    )
}

export const fetchInformetByIdApi = {
    cancel: null,
    run: id => (
        axios.get('/api/informet/getById', {
            cancelToken: new CancelToken(c => fetchInformetByIdApi.cancel = c),
            params: { id },
        })
        .then(({ data }) => data)
    )
}

export const fetchInformetPdfByIdApi = id => (
    axios.get('/api/informet/getPdfById', {
        responseType: 'blob',
        params: { id },
    })
    .then(response => response)
);

export const fetchSendInformetByIdApi = {
    cancel: null,
    run: (id, mailList) => (
        axios.get('/api/informet/sendById', {
            cancelToken: new CancelToken(c => fetchSendInformetByIdApi.cancel = c),
            params: { id, mailList },
        })
        .then(({ data }) => data)
    )
}

export const insertInformetApi = {
    cancel: null,
    run: informet => (
        axios.post('/api/informet', {
            informet,
        }, {
            cancelToken: new CancelToken(c => insertInformetApi.cancel = c),
        })
        .then(({ data }) => data)
    )
}

export const updateInformetApi = {
    cancel: null,
    run: (informet, id) => (
        axios.put('/api/informet', {
            informet, id
        }, {
            cancelToken: new CancelToken(c => updateInformetApi.cancel = c),
        })
        .then(({ data }) => data)
    )
}