import React, { Component } from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';

import InformetTable from '../components/InformetTable';
import { informetsActions } from '../actions';

class InformetTableContainer extends Component {
    componentDidMount(){
        this.props.informetsRequest();
    }
    render() {
        const { 
            list,
            isFetchingList,
            isErrorList,
            statusList,
            goTo,
            informetsRequest,
            informetPdfByIdRequest,
        } = this.props;
        return (
            <InformetTable 
                list={ list }
                isFetching={ isFetchingList }
                isError={ isErrorList }
                status={ statusList }
                informetsRequest= { informetsRequest }
                newInformet={ () => goTo('/nuevo') }
                updateInformet={ id => goTo(`/modificar/${id}`) }
                viewInformet={ id => goTo(`/informes/visualizar/${id}`) }
                getInformetPdf = { informetPdfByIdRequest }
                sendInformet={ id => goTo(`/informes/enviar/${id}`) }
            />
        )
    }
};

const mapStateToProps = ({ informets: { list, isFetchingList, isErrorList, statusList }}) => ({
    list,
    isFetchingList,
    isErrorList,
    statusList,
});

const mapDispatchToProps = dispatch => ({
    goTo: url => dispatch(push(url)),
    informetsRequest: date => dispatch(informetsActions.informetsRequest(date)),
    informetPdfByIdRequest: id => dispatch(informetsActions.informetPdfByIdRequest(id)),
});

const reduxConn = connect(mapStateToProps, mapDispatchToProps);

export default reduxConn(InformetTableContainer);