import styled from 'styled-components';

export const Header = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 1em;
`;

export const Title = styled.div`
    font-size: 1.2em;
    font-weight: bold;
`;

export const Table = styled.table`
    width: 100%;
    border-collapse: collapse;
    overflow-x: scroll;
    overflow-y: scroll;
    display: block;
    white-space: nowrap;
`;

export const Thead = styled.thead`
    border-bottom: 2px solid #BDBDBD;
`;

export const Th = styled.th`
    font-weight: bold;
    color: #757575;
    padding: 0.8em 1em;
    text-align: left;
`;

export const Td = styled.td`
    width: 100%;
    color: #212121;
    padding: 0.8em 1em;
    border-bottom: 1px solid #BDBDBD;
`;

export const IconButton = styled.div`
    cursor: pointer;
`;