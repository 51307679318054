import styled from 'styled-components';

export const Wrapper = styled.div`
    margin: 1em 0em;
`;

export const Title = styled.div`
    background-color: #0097A7;
    border-top-left-radius: 0.5em;
    border-top-right-radius: 0.5em;
    color: #ffffff;
    font-weight: bold;
    padding: 0.5em 1em;
`;

export const Content = styled.div`
    border: 0.1em solid #0097A7;
    border-bottom-left-radius: 0.5em;
    border-bottom-right-radius: 0.5em;
    padding: 0.5em;
`;